import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    super.connect();
    console.log("gallery connected!");
    if (this.hasImageTarget) {
      console.log("found " + this.imageTargets.length + " image targets");
      this.currentImageIndex = 0;
      this.showElement(this.imageTargets[this.currentImageIndex]);
    } else {
      console.log("no images found!");
    }
  }
  next() {
    console.log("next");
    this.hideElement(this.imageTargets[this.currentImageIndex]);
    if (this.currentImageIndex + 1 == this.imageTargets.length) {
      this.currentImageIndex = 0;
    } else {
      this.currentImageIndex++;
    }
    this.showElement(this.imageTargets[this.currentImageIndex]);
  }
  previous() {
    console.log("previous");
    this.hideElement(this.imageTargets[this.currentImageIndex]);
    if (this.currentImageIndex - 1 < 0) {
      this.currentImageIndex = this.imageTargets.length - 1;
    } else {
      this.currentImageIndex--;
    }
    this.showElement(this.imageTargets[this.currentImageIndex]);
  }
  hideElement(el) {
    if (el == undefined) {
      return false;
    }
    el.classList.remove("block");
    el.classList.add("hidden");
  }
  showElement(el) {
    if (el == undefined) {
      return false;
    }
    el.classList.add("block");
    el.classList.remove("hidden");
  }
}
